import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// components
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { useQueryState } from '../../utils/query-state'
import AutoCompleteInput from '../../components/AutoCompleteInput'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useDataApi } from '../../context/DataApiContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import useStyles from './styles'
import NativeSelect from '@mui/material/NativeSelect'

import CreateVehicleTypeDialog from '../../components/VehicleType/components/CreateVehicleTypeDialog'
import ModifyVehicleTypeDialog from '../../components/VehicleType/components/ModifyVehicleTypeDialog'
import CreateVehicleTypeBulkDialog from '../../components/VehicleType/components/CreateVehicleTypeBulkDialog'
import Table from '../../components/Table'
import generateColumns from './columns'
import enums from '../../utils/enums'

export default function VehicleTypesList({ history }) {
  const { t } = useTranslation()

  function useQuery() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { showConfirmationDialog } = useConfirmationDialogProvider()

  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [tableData, setTableData] = useState(null)

  const [openCreateVehicleTypeDialog, setOpenCreateVehicleTypeDialog] =
    useState(false)
  const [openModifyVehicleTypeDialog, setOpenModifyVehicleTypeDialog] =
    useState(false)
  const [vehicleTypeToModify, setVehicleTypeToModify] = useState(false)
  const [openCreateVehicleTypeBulkDialog, setOpenCreateVehicleTypeBulkDialog] =
    useState(false)

  const [addActionsAnchorEl, setAddActionsAnchorEl] = useState({
    anchorEl: null,
    items: [],
  })

  const [depotId, setDepotId] = useQueryState({
    paramName: 'depotId',
    history,
    query,
  })
  const [operatorId, setOperatorId] = useQueryState({
    paramName: 'operatorId',
    history,
    query,
  })
  const [chassisId, setChassisId] = useQueryState({
    paramName: 'chassisId',
    history,
    query,
  })
  const [location, setLocation] = useQueryState({
    paramName: 'location',
    history,
    query,
  })
  const [conformity, setConformity] = useQueryState({
    paramName: 'conformity',
    defaultValue: '',
    history,
    query,
  })
  const [nParc, setNParc] = useQueryState({
    paramName: 'nParc',
    history,
    query,
  })
  const [constructor, setConstructor] = useQueryState({
    paramName: 'constructorId',
    history,
    query,
  })
  const [showInactive, setShowInactive] = useQueryState({
    paramName: 'showInactive',
    history,
    query,
  })

  const computedFilters = useMemo(() => {
    const filtersObj = {
      depotId: depotId ? depotId.id : null,
      operatorId: operatorId ? operatorId.id : null,
      chassisId: chassisId ? chassisId.id : null,
      conformity: enums.CONFORMITY.isValid(conformity) ? conformity : null,
      location: location ? location.id : null,
      nParc: nParc ? nParc.title : null,
      constructorId: constructor ? constructor.id : null,
      showInactive: showInactive === '1' ? true : false,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key]
    )
    return filtersObj
  })

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({
        page: tableState.page,
        sort: null,
        filters: computedFilters,
      })
    } else {
      setTableState({ page: 0, sort: null, filters: null })
    }
  }

  const resource = 'vehicle-type'

  useEffect(() => {
    dataProvider
      .getList(resource, {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      })
      .then((response) => {
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const onModify = (rowIndex) => {
    setVehicleTypeToModify(tableData.items[rowIndex])
    setOpenModifyVehicleTypeDialog(true)
  }

  const onDeactivate = (rowIndex) => {
    const { id, type, specificity } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('vehicle-types-list.confirm-vehicle-type-deactivation', {
        type,
        specificity,
      }),
      () => {
        dataProvider
          .post(`vehicle-type/${id}/deactivate`, {})
          .then(() => {
            refreshData(true)
            showNotification(
              'success',
              t('vehicle-types-list.deactivate-success')
            )
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      },
      t('common.deactivate')
    )
  }

  const onActivate = (rowIndex) => {
    const { id, type, specificity } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('vehicle-types-list.confirm-vehicle-type-activation', {
        type,
        specificity,
      }),
      () => {
        dataProvider
          .post(`vehicle-type/${id}/activate`, {})
          .then(() => {
            refreshData(true)
            showNotification(
              'success',
              t('vehicle-types-list.activate-success')
            )
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      },
      t('common.activate')
    )
  }

  const onDelete = (rowIndex) => {
    const { id, type, specificity } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('vehicle-types-list.confirm-vehicle-type-deletion', {
        type,
        specificity,
      }),
      () => {
        dataProvider
          .delete(`vehicle-type`, { id })
          .then(() => {
            refreshData()
            showNotification('success', t('vehicle-types-list.delete-success'))
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      },
      t('common.delete')
    )
  }

  const updateFilters = () => {
    setTableState({
      ...tableState,
      page: 0,
      filters: computedFilters,
    })
  }

  const clearFilters = () => {
    setDepotId(null)
    setOperatorId(null)
    setChassisId(null)
    setLocation(null)
    setConformity('')
    setNParc(null)
    setConstructor(null)
    setShowInactive('0')
    setTableState({
      ...tableState,
      page: 0,
      filters: null,
    })
  }

  const tableColumns = generateColumns({
    t,
    classes,
    onDeactivate,
    onActivate,
    onModify,
    onDelete,
  })

  const onAddActionsMenuClose = () => {
    setAddActionsAnchorEl({
      ...addActionsAnchorEl,
      anchorEl: null,
      items: [],
    })
  }

  const onAddActions = (currentTarget) => {
    const menuItems = []

    menuItems.push({
      title: t('vehicle-types-list.manual-add'),
      onClick: () => {
        onAddActionsMenuClose()
        setOpenCreateVehicleTypeDialog(true)
      },
    })
    menuItems.push({
      title: t('vehicle-types-list.bulk-add'),
      onClick: () => {
        onAddActionsMenuClose()
        setOpenCreateVehicleTypeBulkDialog(true)
      },
    })

    setAddActionsAnchorEl({
      ...addActionsAnchorEl,
      anchorEl: currentTarget,
      items: menuItems,
    })
  }

  const onSeeDetails = (rowIndex) => {
    const vehicleTypeId = tableData.items[rowIndex].id
    history.push(`/vehicle-types/detail/${vehicleTypeId}`)
  }

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateVehicleTypeBulkDialog
        open={openCreateVehicleTypeBulkDialog}
        onClose={() => {
          refreshData()
          setOpenCreateVehicleTypeBulkDialog(false)
        }}
        onCreated={() => {
          refreshData()
          setOpenCreateVehicleTypeBulkDialog(false)
        }}
      />
      <CreateVehicleTypeDialog
        open={openCreateVehicleTypeDialog}
        onClose={() => setOpenCreateVehicleTypeDialog(false)}
        onCreated={() => {
          refreshData()
          setOpenCreateVehicleTypeDialog(false)
        }}
      />
      <ModifyVehicleTypeDialog
        open={openModifyVehicleTypeDialog}
        onClose={() => {
          setOpenModifyVehicleTypeDialog(false)
          setVehicleTypeToModify({})
        }}
        originalVehicleType={vehicleTypeToModify}
        onModified={() => {
          refreshData(true)
          setOpenModifyVehicleTypeDialog(false)
        }}
      />
      <Menu
        keepMounted
        anchorEl={addActionsAnchorEl.anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onAddActionsMenuClose}
        open={Boolean(addActionsAnchorEl.anchorEl)}
      >
        {addActionsAnchorEl.items.map((item) => (
          <MenuItem key={item.title} onClick={item.onClick}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('vehicle-types-list.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={(event) => onAddActions(event.currentTarget)}
          >
            {t('dashboard-view.add-vehicle-type')}
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.filtersContainer}>
        <Grid container spacing={4} direction="column">
          <Grid item>
            <Typography
              variant="h6"
              className={classes.filterContainerTitleText}
            >
              {t('common.filterZone')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              justifyContent="flex-start"
              spacing={1}
              wrap="wrap"
              direction="row"
            >
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setDepotId}
                  label={t('common.depot')}
                  resource="chassis/depot-autocomplete"
                  value={depotId}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setOperatorId}
                  label={t('common.operateur')}
                  resource="chassis/operateur-autocomplete"
                  value={operatorId}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant="standard" className={classes.selector}>
                  <InputLabel
                    id="conformity-label"
                    shrink={enums.CONFORMITY.isValid(conformity)}
                  >
                    {t('common.conformity')}
                  </InputLabel>
                  <NativeSelect
                    labelid="conformity-label"
                    inputProps={{
                      name: t('common.conformity'),
                      id: 'conformity',
                    }}
                    onChange={(event) => {
                      setConformity(event.target.value)
                    }}
                    value={conformity}
                  >
                    <option aria-label="None" value="" />
                    <option value={enums.CONFORMITY.VALID}>
                      {t('conformity.valid')}
                    </option>
                    <option value={enums.CONFORMITY.INVALID}>
                      {t('conformity.invalid')}
                    </option>
                    <option value={enums.CONFORMITY.AWAITING_CONTROL}>
                      {t('conformity.waiting-result')}
                    </option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setChassisId}
                  label={t('common.chassis')}
                  resource="chassis/chassis-number-autocomplete"
                  value={chassisId}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setLocation}
                  label={t('common.location')}
                  resource="chassis/location-autocomplete"
                  value={location}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setNParc}
                  label={t('common.nParc')}
                  resource="chassis/parc-number-autocomplete"
                  value={nParc}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setConstructor}
                  label={t('common.constructeurs')}
                  resource="constructors/autocomplete"
                  value={constructor}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3} marginTop={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(event) =>
                        setShowInactive(event.target.checked ? '1' : '0')
                      }
                      checked={showInactive === '1'}
                    />
                  }
                  label={t('vehicle-types-list.show-deactivated')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              className={classes.actionsContainer}
            >
              <Grid item xs={12} md={2} xl={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={clearFilters}
                >
                  {t('common.clean')}
                </Button>
              </Grid>
              <Grid item xs={12} md={2} xl={1}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={updateFilters}
                >
                  {t('common.search')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Table
        id={resource}
        columns={tableColumns}
        data={tableData}
        page={tableState.page}
        sort={tableState.sort}
        onChangePage={(currentPage) => {
          setTableState({ ...tableState, page: currentPage })
        }}
        onColumnSortChange={(changedColumn, direction) => {
          const newSort = {
            field: changedColumn,
            direction: direction.toUpperCase(),
          }
          setTableState({ ...tableState, sort: newSort })
        }}
        onRowClick={(_, cellMeta) => {
          onSeeDetails(cellMeta.rowIndex)
        }}
      />
    </>
  )
}
