import React from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import classnames from 'classnames'

// styles
import useStyles from './styles'

// components
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'

// pages
import Dashboard from '../pages/dashboard'
import Error from '../pages/error'

// context
import { useLayoutState } from '../context/LayoutContext'
import { useUserState, defaultRoute } from '../context/user/UserContext'
import VehicleTypesList from '../pages/vehicle-types-list'
import ChassisList from '../pages/chassis-list'
import ChassisDetail from '../pages/chassis-detail'
import ControlsList from '../pages/controls-list'
import ControlsDetailList from '../pages/controls-detail-list'
import UsersList from '../pages/users-list'
import ControlsDetail from '../pages/controls-detail'
import ConstructorsList from '../pages/constructor-list'
import OperatorsList from '../pages/operators-list'
import ContractorsList from '../pages/contractor-list'
import MembersList from '../pages/members-list'
import Permissions from '../pages/permissions'
import VehicleTypeDetail from '../pages/vehicle-type-detail'
import DepotsList from '../pages/depots-list'
import EmailListsList from '../pages/email-lists-list'
import Statistics from '../pages/statistics'
import PVAdmissionManagement from '../pages/pv-admission-management'

function Layout({ history }) {
  const classes = useStyles()
  const { user } = useUserState()

  // global
  const layoutState = useLayoutState()

  return (
    <div className={classes.root}>
      <>
        <Header history={history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to={defaultRoute(user)} />}
            />
            <Route path="/dashboard" component={Dashboard} exact />
            <Route path="/dashboard/statistics" component={Statistics} />
            <Route
              path="/vehicle-types/list"
              component={VehicleTypesList}
              exact
            />
            <Route
              path="/vehicle-types/detail/:id"
              component={VehicleTypeDetail}
            />
            <Route path="/controls/list" component={ControlsList} exact />
            <Route
              path="/controls/detail-list"
              component={ControlsDetailList}
            />
            <Route path="/controls/detail/:id" component={ControlsDetail} />
            <Route path="/chassis/list" component={ChassisList} />
            <Route
              path="/chassis/pv-admission-management"
              component={PVAdmissionManagement}
            />
            <Route path="/chassis/detail/:id" component={ChassisDetail} />
            <Route path="/users/list" component={UsersList} exact />
            <Route path="/users/permissions" component={Permissions} />
            <Route path="/constructors/list" component={ConstructorsList} />
            <Route path="/operators/list" component={OperatorsList} />
            <Route path="/contractors/list" component={ContractorsList} />
            <Route path="/members/list" component={MembersList} />
            <Route path="/depots/list" component={DepotsList} />
            <Route path="/email-lists/list" component={EmailListsList} />
            <Route component={Error} />
          </Switch>
        </div>
      </>
    </div>
  )
}

export default withRouter(Layout)
