import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
// @mui
import { Box, Typography, Skeleton } from '@mui/material';
import {
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLabel,
} from 'victory';
import ChartFilterBadges from '../chart-filter-badges';

LineChart.propTypes = {
  title: PropTypes.string,
  noDataMessage: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      y: PropTypes.number,
    })
  ),
  style: PropTypes.shape({
    chart: PropTypes.object,
    line: PropTypes.object,
    tooltip: PropTypes.object,
    xLabel: PropTypes.object,
    yLabel: PropTypes.object,
    title: PropTypes.object,
    activeFilters: PropTypes.object,
  }),
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  tooltipLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  showAllTicks: PropTypes.bool,
  activeFilters: PropTypes.array,
};

export default function LineChart({ title, noDataMessage, data, style, xLabel, yLabel, tooltipLabel, isLoading, showAllTicks, activeFilters }) {
  const theme = useTheme();

  // Function to format the tooltip label based on the template
  const formatTooltip = (x, y) => {
    const roundedY = Math.round(y * 100) / 100; // Round to 2 decimal places
    return tooltipLabel.replace('{x}', x).replace('{y}', roundedY);
  };

  const dataLength = data?.length ?? 0;

  // Determine the optimal number of middle labels (1, 2, or 3)
  let middleLabelCount;
  if (dataLength < 20) {
    middleLabelCount = 1;
  } else if (dataLength < 40) {
    middleLabelCount = 2;
  } else {
    middleLabelCount = 3;
  }

  // Calculate indices for the tick labels
  const firstIndex = 0;
  const lastIndex = dataLength - 1;
  const interval = Math.floor(dataLength / (middleLabelCount + 1));

  // Generate middle tick indices based on the interval
  const middleIndices = Array.from({ length: middleLabelCount }, (_, i) => (i + 1) * interval);

  // Combine tick indices: [first, middle1, middle2, ..., last]
  const tickIndices = !isLoading && dataLength !== 0 ? [firstIndex, ...middleIndices, lastIndex] : [];

  const allZero = data?.every((datum) => datum.y === 0);

  if (isLoading) {
    return (
      <Box>
        <Typography style={style?.title} marginLeft={1} marginBottom={1}>
          {title}
        </Typography>
        <Skeleton variant="rectangular" width="100%" height={350} animation="pulse" style={{ borderRadius: 8, backgroundColor: theme.palette.grey[200] }} />
      </Box>
    );
  }

  return (
    <Box>
      <Typography style={style.title} marginLeft={1} marginBottom={-1}>{title}</Typography>
      <ChartFilterBadges filters={activeFilters} style={style?.activeFilters} />
      <VictoryChart
        theme={VictoryTheme.clean}
        containerComponent={<VictoryVoronoiContainer />}
        padding={style?.chart?.padding}
        domain={{
          y: allZero ? [0, 1] : undefined, // Default domain when all values are 0
        }}
      >
        {/* X-axis */}
        <VictoryAxis label={xLabel} style={style.xLabel} tickFormat={(x, index) => {
          if (showAllTicks) {
            return x.replace(' ', '\n');
          }

          if (tickIndices.includes(index)) {
            return x
          }
          return ''; // Hide other labels
        }} />
        {/* Y-axis */}
        <VictoryAxis dependentAxis label={yLabel} style={style.yLabel} />
        {/* Line with Tooltip */}
        {/* Conditionally render VictoryLine or VictoryLabel */}
        {!isLoading && dataLength !== 0 ? (
          <VictoryLine
            data={data}
            style={style?.line}
            labels={({ datum }) => formatTooltip(datum.x, datum.y)}
            labelComponent={<VictoryTooltip style={style?.tooltip} />}
            animate={{ duration: 1000, easing: 'linear', onLoad: { duration: 1500 } }}
          />
        ) : (
          <VictoryLabel
            text={noDataMessage}
            x={225}
            y={150}
            textAnchor="middle"
            style={{ fontSize: 14, fill: theme.palette.text.disabled }}
          />
        )}
      </VictoryChart>
    </Box>
  );
}