const Permissions = Object.freeze({
  PAGE_DASHBOARD_ROOT: 'page-dashboard:root',
  PAGE_DASHBOARD_ADD_VEHICLE_TYPE: 'page-dashboard:add-vehicle-type',
  PAGE_DASHBOARD_ADD_CHASSIS: 'page-dashboard:add-chassis',
  PAGE_STATISTICS_ROOT: 'page-statistics:root',
  PAGE_VEHICLES_TYPE_ROOT: 'page-vehicles-type:root',
  PAGE_CONTROLS_ROOT: 'page-controls:root',
  PAGE_CONTROLS_RESEND_CONTROL_RESULT: 'page-controls:resend-control-result',
  PAGE_CONTROLS_ACCESS_PV_ADMISSION: 'page-controls:access-pv-admission',
  PAGE_CONTROLS_DELETE_WAITING_RESULT:
    'page-controls:delete-waiting-result-controls',
  PAGE_CHASSIS_ROOT: 'page-chassis:root',
  PAGE_CHASSIS_ADD_CHASSIS: 'page-chassis:add-chassis',
  PAGE_CHASSIS_ADD_CONTROL: 'page-chassis:add-control',
  PAGE_CHASSIS_ADD_DOCUMENTS: 'page-chassis:add-documents',
  PAGE_CHASSIS_DELETE_DOCUMENTS: 'page-chassis:delete-documents',
  PAGE_CHASSIS_SEE_CHASSIS_CONTROL_DETAILS:
    'page-chassis:see-chassis-control-details',
  PAGE_CHASSIS_MODIFY_CHASSIS_OPERATOR_DEPOT_LOCATION:
    'page-chassis:modify-chassis-operator-depot-location',
  PAGE_CHASSIS_MODIFY_CHASSIS_DOCUMENTS:
    'page-chassis:modify-chassis-documents',
  PAGE_CHASSIS_MODIFY_CHASSIS_CONTROL_DATE:
    'page-chassis:modify-chassis-control-date',
  PAGE_CHASSIS_OVERRIDE_VALIDATION: 'page-chassis:override-chassis-validation',
  PAGE_CHASSIS_DELETE_CHASSIS: 'page-chassis:delete-chassis',
  PAGE_PV_ADMISSION_MANAGEMENT: 'page-pv-admission-management:root',
  PAGE_CONSTRUCTORS_ROOT: 'page-constructors:root',
  PAGE_MEMBERS_ROOT: 'page-members:root',
  PAGE_OPERATORS_ROOT: 'page-operators:root',
  PAGE_CONTRACTORS_ROOT: 'page-providers:root',
  PAGE_DEPOTS_ROOT: 'page-depots:root',
  PAGE_EMAILS_LISTS_ROOT: 'page-emails-lists:root',
  PAGE_USERS_ROOT: 'page-users:root',
  PAGE_USERS_MODIFY_USER_TYPE: 'page-users:modify-user-type',
  PAGE_USERS_MANAGE_USER_STATE: 'page-users:manage-user-state',
  PAGE_USERS_MANAGE_PASSWORDS: 'page-users:manage-passwords',
  PAGE_USERS_DELETE_USER: 'page-users:delete-user',
  PAGE_PERMISSION_ROOT: 'page-permissions:root',
  APPLICATION_ACCESS: 'application-access',
})

const translations = {
  [Permissions.PAGE_DASHBOARD_ROOT]: 'permissions.page-dashboard.root',
  [Permissions.PAGE_DASHBOARD_ADD_VEHICLE_TYPE]:
    'permissions.page-dashboard.add-vehicle-type',
  [Permissions.PAGE_DASHBOARD_ADD_CHASSIS]:
    'permissions.page-dashboard.add-chassis',
  [Permissions.PAGE_STATISTICS_ROOT]: 'permissions.page-statistics.root',
  [Permissions.PAGE_VEHICLES_TYPE_ROOT]: 'permissions.page-vehicles-type.root',
  [Permissions.PAGE_CONTROLS_ROOT]: 'permissions.page-controls.root',
  [Permissions.PAGE_CONTROLS_RESEND_CONTROL_RESULT]:
    'permissions.page-controls.resend-control-result',
  [Permissions.PAGE_CONTROLS_ACCESS_PV_ADMISSION]:
    'permissions.page-controls.access-pv-admission',
  [Permissions.PAGE_CHASSIS_ROOT]: 'permissions.page-chassis.root',
  [Permissions.PAGE_CHASSIS_ADD_CHASSIS]:
    'permissions.page-chassis.add-chassis',
  [Permissions.PAGE_CHASSIS_ADD_CONTROL]:
    'permissions.page-chassis.add-control',
  [Permissions.PAGE_CHASSIS_ADD_DOCUMENTS]:
    'permissions.page-chassis.add-documents',
  [Permissions.PAGE_CHASSIS_DELETE_DOCUMENTS]:
    'permissions.page-chassis.delete-documents',
  [Permissions.PAGE_CHASSIS_SEE_CHASSIS_CONTROL_DETAILS]:
    'permissions.page-chassis.see-chassis-control-details',
  [Permissions.PAGE_CHASSIS_MODIFY_CHASSIS_OPERATOR_DEPOT_LOCATION]:
    'permissions.page-chassis.modify-chassis-operator-depot-location',
  [Permissions.PAGE_CHASSIS_MODIFY_CHASSIS_DOCUMENTS]:
    'permissions.page-chassis.modify-chassis-documents',
  [Permissions.PAGE_CHASSIS_MODIFY_CHASSIS_CONTROL_DATE]:
    'permissions.page-chassis.modify-chassis-control-date',
  [Permissions.PAGE_CHASSIS_DELETE_CHASSIS]:
    'permissions.page-chassis.delete-chassis',
  [Permissions.PAGE_PV_ADMISSION_MANAGEMENT]:
    'permissions.page-pv-admission-management.root',
  [Permissions.PAGE_CONSTRUCTORS_ROOT]: 'permissions.page-constructors.root',
  [Permissions.PAGE_MEMBERS_ROOT]: 'permissions.page-members.root',
  [Permissions.PAGE_OPERATORS_ROOT]: 'permissions.page-operators.root',
  [Permissions.PAGE_CONTRACTORS_ROOT]: 'permissions.page-contractors.root',
  [Permissions.PAGE_DEPOTS_ROOT]: 'permissions.page-depots.root',
  [Permissions.PAGE_EMAILS_LISTS_ROOT]: 'permissions.page-emails-lists.root',
  [Permissions.PAGE_USERS_ROOT]: 'permissions.page-users.root',
  [Permissions.PAGE_USERS_MODIFY_USER_TYPE]:
    'permissions.page-users.modify-user-type',
  [Permissions.PAGE_USERS_MANAGE_USER_STATE]:
    'permissions.page-users.manage-user-state',
  [Permissions.PAGE_USERS_MANAGE_PASSWORDS]:
    'permissions.page-users.manage-passwords',
  [Permissions.APPLICATION_ACCESS]: 'permissions.application-access',
}

const permissionTree = {
  [Permissions.PAGE_DASHBOARD_ROOT]: [
    Permissions.PAGE_DASHBOARD_ADD_VEHICLE_TYPE,
    Permissions.PAGE_DASHBOARD_ADD_CHASSIS,
  ],
  [Permissions.PAGE_STATISTICS_ROOT]: [],
  [Permissions.PAGE_VEHICLES_TYPE_ROOT]: [],
  [Permissions.PAGE_CONTROLS_ROOT]: [
    Permissions.PAGE_CONTROLS_RESEND_CONTROL_RESULT,
    Permissions.PAGE_CONTROLS_ACCESS_PV_ADMISSION,
  ],
  [Permissions.PAGE_CHASSIS_ROOT]: [
    Permissions.PAGE_CHASSIS_ADD_CHASSIS,
    Permissions.PAGE_CHASSIS_ADD_CONTROL,
    Permissions.PAGE_CHASSIS_ADD_DOCUMENTS,
    Permissions.PAGE_CHASSIS_DELETE_DOCUMENTS,
    Permissions.PAGE_CHASSIS_SEE_CHASSIS_CONTROL_DETAILS,
    Permissions.PAGE_CHASSIS_MODIFY_CHASSIS_OPERATOR_DEPOT_LOCATION,
    Permissions.PAGE_CHASSIS_MODIFY_CHASSIS_DOCUMENTS,
    Permissions.PAGE_CHASSIS_MODIFY_CHASSIS_CONTROL_DATE,
    Permissions.PAGE_CHASSIS_DELETE_CHASSIS,
  ],
  [Permissions.PAGE_PV_ADMISSION_MANAGEMENT]: [],
  [Permissions.PAGE_CONSTRUCTORS_ROOT]: [],
  [Permissions.PAGE_MEMBERS_ROOT]: [],
  [Permissions.PAGE_OPERATORS_ROOT]: [],
  [Permissions.PAGE_CONTRACTORS_ROOT]: [],
  [Permissions.PAGE_DEPOTS_ROOT]: [],
  [Permissions.PAGE_EMAILS_LISTS_ROOT]: [],
  [Permissions.PAGE_USERS_ROOT]: [
    Permissions.PAGE_USERS_MODIFY_USER_TYPE,
    Permissions.PAGE_USERS_MANAGE_USER_STATE,
    Permissions.PAGE_USERS_MANAGE_PASSWORDS,
  ],
  [Permissions.APPLICATION_ACCESS]: [],
}

const Roles = Object.freeze({
  CATP: 'catp',
  MEMBER: 'member',
  OPERATOR: 'operator',
  CONTRACTOR: 'contractor',
  CONSTRUCTOR: 'constructor',
})

function translate(permission, t) {
  return t(translations[permission])
}

export { Permissions, translate, permissionTree, Roles }
