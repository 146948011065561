import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function ModifyVehicleTypeDialog({
  open,
  onClose,
  originalVehicleType,
  onModified,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [type, setType] = useState(null)
  const [specificity, setSpecificity] = useState()
  const [constructeur, setConstructor] = useState(null)
  const [member, setMember] = useState(null)
  const [frameworkAgreement, setFrameworkAgreement] = useState(null)
  const [contractNumber, setContractNumber] = useState(null)
  const [lotNumber, setLotNumber] = useState(null)
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(null)

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setType('')
    setSpecificity('')
    setConstructor(null)
    setMember(null)
    setFrameworkAgreement(null)
    setContractNumber(null)
    setLotNumber(null)
    setPurchaseOrderNumber(null)
  }

  useEffect(() => {
    if (!originalVehicleType) {
      return
    }

    setType(originalVehicleType.type)
    setSpecificity(originalVehicleType.specificity)
    if (originalVehicleType.constructor && originalVehicleType.constructor.id) {
      setConstructor({
        id: originalVehicleType.constructor.id,
        title: originalVehicleType.constructor.name,
      })
    } else {
      setConstructor(null)
    }
    if (originalVehicleType.member) {
      setMember({
        id: originalVehicleType.member.id,
        title: originalVehicleType.member.name,
      })
    } else {
      setMember(null)
    }
    if (originalVehicleType.frameworkAgreement) {
      setFrameworkAgreement(originalVehicleType.frameworkAgreement)
    } else {
      setFrameworkAgreement(null)
    }
    if (originalVehicleType.contractNumber) {
      setContractNumber(originalVehicleType.contractNumber)
    } else {
      setContractNumber(null)
    }
    if (originalVehicleType.lotNumber) {
      setLotNumber(originalVehicleType.lotNumber)
    } else {
      setLotNumber(null)
    }
    if (originalVehicleType.purchaseOrderNumber) {
      setPurchaseOrderNumber(originalVehicleType.purchaseOrderNumber)
    } else {
      setPurchaseOrderNumber(null)
    }
  }, [originalVehicleType])

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (type.length === 0) {
      setError('type_required')
      return
    }

    if (specificity.length === 0) {
      setError('specificity_required')
      return
    }

    const data = {
      type,
      specificity,
    }
    if (constructeur) {
      data.constructorId = constructeur.id
    }
    if (member) {
      data.memberId = member.id
    }
    if (frameworkAgreement) {
      data.frameworkAgreement = frameworkAgreement
    }
    if (contractNumber) {
      data.contractNumber = contractNumber
    }
    if (lotNumber) {
      data.lotNumber = lotNumber
    }
    if (purchaseOrderNumber) {
      data.purchaseOrderNumber = purchaseOrderNumber
    }

    setLoading(true)
    dataProvider
      .update('vehicle-type', { data, id: originalVehicleType.id })
      .then(() => {
        dataProvider.cache.reset()
        dataProvider.member.resetCache()
        showNotification('success', t('modify-vehicle-type-dialog.success'))
        onModified()
        resetState()
      })
      .catch((err) => {
        if (err.response.data.error === 'VEHICLE_TYPE_ALREADY_EXISTS') {
          showNotification(
            'error',
            t('modify-vehicle-type-dialog.already-exists-error')
          )
        } else {
          showNotification('error', t('errors.api-error'))
        }
        setLoading(false)
      })
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('modify-vehicle-type-dialog.title')}
      loading={loading}
      openButtonTitle={t('common.modify')}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'type_required'}
            helperText={t('common.required')}
            id="type"
            label={t('modify-vehicle-type-dialog.type')}
            fullWidth
            value={type}
            onChange={(event) => setType(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'specificity_required'}
            helperText={t('common.required')}
            id="specificity"
            label={t('modify-vehicle-type-dialog.specificity')}
            fullWidth
            value={specificity}
            onChange={(event) => setSpecificity(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setConstructor}
            value={constructeur}
            label={t('create-vehicle-type-dialog.constructor-label')}
            resource="/constructors/autocomplete"
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setMember}
            value={member}
            label={t('create-vehicle-type-dialog.member')}
            resource="/vehicle-type/autocomplete-members"
            canDelete
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            id="frameworkAgreement"
            label={t('create-vehicle-type-dialog.framework-agreement')}
            fullWidth
            value={frameworkAgreement}
            onChange={(event) => setFrameworkAgreement(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            id="contractNumber"
            label={t('create-vehicle-type-dialog.contract-number')}
            fullWidth
            value={contractNumber}
            onChange={(event) => setContractNumber(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            id="lotNumber"
            label={t('create-vehicle-type-dialog.lot-number')}
            fullWidth
            value={lotNumber}
            onChange={(event) => setLotNumber(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            id="purchaseOrderNumber"
            label={t('create-vehicle-type-dialog.purchase-order-number')}
            fullWidth
            value={purchaseOrderNumber}
            onChange={(event) => setPurchaseOrderNumber(event.target.value)}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
