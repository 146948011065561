import React from 'react'

import authApi from '../../api/authApi'
import { Permissions } from '../../entities/permissions'
import Storages from './storages'

const UserContext = React.createContext()

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      Storages.user.persist(action.payload.user)
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
      }
    case 'LOGIN_FAILURE':
      Storages.user.clear()
      return { ...state, user: null, isAuthenticated: false }
    case 'UPDATE_USER':
      Storages.user.persist(action.payload.user)
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: !!action.payload.user,
      }
    case 'SIGN_OUT_SUCCESS':
      Storages.user.clear()
      Storages.tokens.clear()
      return { ...state, isAuthenticated: false }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function UserProvider({ children }) {
  const persistedUser = Storages.user.inflate()
  const persistedTokens = Storages.tokens.inflate()
  const userPermissions = {
    chassis: { read: true, write: true },
    vehicleTypes: { read: true, write: true },
    users: { read: true, write: true },
    controls: { read: true, write: true },
  }

  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!persistedUser && !!persistedTokens,
    user: persistedUser,
    userPermissions,
  })

  const context = {
    state,
    dispatch,
  }
  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

function useUserState() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context.state
}

function useUserDispatch() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return context.dispatch
}

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(null)
  setIsLoading(true)
  authApi
    .loginUser(login, password)
    .then((res) => {
      const responsePayload = res.data
      Storages.tokens.persist({
        token: responsePayload.token,
        refreshToken: responsePayload.refreshToken,
      })
      setIsLoading(false)
      // set user
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: { user: responsePayload.user },
      })
      history.push('/')
    })
    .catch(() => {
      setError('errors.wrongPassword')
      setIsLoading(false)
    })
}

function signOut(dispatch, history) {
  dispatch({ type: 'SIGN_OUT_SUCCESS' })
  history.push('/login')
}

const refreshUser = (dispatch) => {
  authApi
    .fetchUserRequest()
    .then((refreshResponse) =>
      dispatch({ type: 'UPDATE_USER', payload: { user: refreshResponse.data } })
    )
}

function hasPermission(permission, user) {
  const permissions = (user || {}).permissions || []
  return permissions.some((p) => p === permission)
}

function defaultRoute(user) {
  if (hasPermission(Permissions.PAGE_DASHBOARD_ROOT, user)) {
    return '/dashboard'
  }
  if (hasPermission(Permissions.PAGE_STATISTICS_ROOT, user)) {
    return '/dashboard/statistics'
  }
  if (hasPermission(Permissions.PAGE_VEHICLES_TYPE_ROOT, user)) {
    return '/vehicle-types/list'
  }
  if (hasPermission(Permissions.PAGE_CONTROLS_ROOT, user)) {
    return '/controls/list'
  }
  if (hasPermission(Permissions.PAGE_CHASSIS_ROOT, user)) {
    return '/chassis/list'
  }
  if (hasPermission(Permissions.PAGE_PV_ADMISSION_MANAGEMENT, user)) {
    return '/chassis/pv-admission-management'
  }
  if (hasPermission(Permissions.PAGE_CONSTRUCTORS_ROOT, user)) {
    return '/constructors/list'
  }
  if (hasPermission(Permissions.PAGE_MEMBERS_ROOT, user)) {
    return '/members/list'
  }
  if (hasPermission(Permissions.PAGE_OPERATORS_ROOT, user)) {
    return '/operators/list'
  }
  if (hasPermission(Permissions.PAGE_CONTRACTORS_ROOT, user)) {
    return '/contractors/list'
  }
  if (hasPermission(Permissions.PAGE_PERMISSION_ROOT, user)) {
    return '/users/permissions'
  }
  if (hasPermission(Permissions.PAGE_USERS_ROOT, user)) {
    return '/users/list'
  }

  return '/unknown'
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  refreshUser,
  hasPermission,
  defaultRoute,
}
