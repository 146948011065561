import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
// @mui
import { Box, Typography, Skeleton } from '@mui/material';
import {
  VictoryChart,
  VictoryBar,
  VictoryTheme,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLabel,
} from 'victory';
import ChartFilterBadges from '../chart-filter-badges';

BarsChart.propTypes = {
  title: PropTypes.string,
  noDataMessage: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      y: PropTypes.number,
    })
  ),
  style: PropTypes.shape({
    chart: PropTypes.object,
    bars: PropTypes.object,
    tooltip: PropTypes.object,
    xLabel: PropTypes.object,
    yLabel: PropTypes.object,
    title: PropTypes.object,
    activeFilters: PropTypes.object,
  }),
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  tooltipLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  showAllTicks: PropTypes.bool,
  activeFilters: PropTypes.array,
  scrollLimit: PropTypes.number,
  chartHeight: PropTypes.number,
  chartMinimumWidth: PropTypes.number,
};

export default function BarsChart({ title, noDataMessage, data, style, xLabel, yLabel, tooltipLabel, isLoading, showAllTicks, activeFilters, scrollLimit, chartHeight, chartMinimumWidth }) {
  const theme = useTheme();

  // Function to format the tooltip label based on the template
  const formatTooltip = (x, y, z, k, w) => {
    const roundedY = Math.round(y * 100) / 100; // Round to 2 decimal places
    const roundedZ = Math.round(z * 100) / 100; // Round to 2 decimal places
    const roundedK = Math.round(k * 100) / 100; // Round to 2 decimal places
    const roundedW = Math.round(w * 100) / 100; // Round to 2 decimal places
    const kStatus = roundedK > 100 ? '🔴' : '🟢'; // Red circle or green circle

    return tooltipLabel
      .replace('{x}', x)
      .replace('{y}', roundedY)
      .replace('{z}', roundedZ)
      .replace('{k}', `${kStatus} ${roundedK}`)
      .replace('{w}', roundedW);
  };

  const dataLength = data?.length ?? 0;

  // Determine the optimal number of middle labels (1, 2, or 3)
  let middleLabelCount;
  if (dataLength < 20) {
    middleLabelCount = 1;
  } else if (dataLength < 40) {
    middleLabelCount = 2;
  } else {
    middleLabelCount = 3;
  }

  // Calculate indices for the tick labels
  const firstIndex = 0;
  const lastIndex = dataLength - 1;
  const interval = Math.floor(dataLength / (middleLabelCount + 1));

  // Generate middle tick indices based on the interval
  const middleIndices = Array.from({ length: middleLabelCount }, (_, i) => (i + 1) * interval);

  // Combine tick indices: [first, middle1, middle2, ..., last]
  const tickIndices = !isLoading && dataLength !== 0 ? [firstIndex, ...middleIndices, lastIndex] : [];

  if (isLoading) {
    return (
      <Box>
        <Typography style={style?.title} marginLeft={1} marginBottom={1}>
          {title}
        </Typography>
        <Skeleton variant="rectangular" width="100%" height={350} animation="pulse" style={{ borderRadius: 8, backgroundColor: theme.palette.grey[200] }} />
      </Box>
    );
  }

  // Calculate chart width based on the number of data points
  const scroll = dataLength > scrollLimit;
  const chartWidth = Math.max(dataLength * 40, chartMinimumWidth); // 40px per bar or a minimum width of 800px

  const allZero = data?.every((datum) => datum.y === 0);

  const barWidth = (dataLength >= 10 && dataLength < scrollLimit) ? 20 : 30;

  return (
    <Box>
      <Typography style={style.title} marginLeft={1} marginBottom={-1}>{title}</Typography>
      <ChartFilterBadges filters={activeFilters} style={style?.activeFilters} />
      <Box sx={scroll ? { overflowY: 'hidden', overflowX: 'auto', width: '100%', height: `${chartHeight}px` } : {}}>
        <Box sx={scroll ? { width: `${chartWidth}px`, height: '100%' } : {}}>
          <VictoryChart
            theme={VictoryTheme.clean}
            containerComponent={<VictoryVoronoiContainer />}
            domainPadding={{ x: 30 }} // Add padding to the x-axis
            domain={{
              y: allZero ? [0, 1] : undefined, // Default domain when all values are 0
            }}
            padding={style?.chart?.padding}
            {
            ...scroll ? { width: chartWidth, height: chartHeight } : {}
            }
          >
            {/* X-axis */}
            <VictoryAxis label={xLabel} style={style.xLabel} tickFormat={(x, index) => {
              if (showAllTicks) {
                // Replace space with newline for better readability setting 2 lines and ellipse the end of second line
                return x.toString().replace(' ', '\n').replace(/(.{15})..+/, '$1…');
              }

              if (tickIndices.includes(index)) {
                return x
              }
              return ''; // Hide other labels
            }} />
            {/* Y-axis */}
            <VictoryAxis dependentAxis label={yLabel} style={style.yLabel} />
            {/* Line with Tooltip */}
            {/* Conditionally render VictoryLine or VictoryLabel */}
            {!isLoading && dataLength !== 0 ? (
              <VictoryBar
                data={data}
                style={style?.bars}
                labels={({ datum }) => formatTooltip(datum.x, datum.y, datum.z, datum.k, datum.w)}
                labelComponent={<VictoryTooltip constrainToVisibleArea style={style?.tooltip} />}
                barWidth={barWidth}
                animate={{ duration: 1000, easing: 'linear', onLoad: { duration: 1500 } }}
              />
            ) : (
              <VictoryLabel
                text={noDataMessage}
                x={225}
                y={150}
                textAnchor="middle"
                style={{ fontSize: 14, fill: theme.palette.text.disabled }}
              />
            )}
          </VictoryChart>
        </Box>
      </Box>
    </Box>
  );
}