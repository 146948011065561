import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import BaseDialog from '../../../BaseDialog'
import useStyles from './styles'
import { useDataApi } from '../../../../context/DataApiContext'

import WizardHeader from '../../../WizardHeader'
import VehicleTypeDetailsStep from './steps/VehicleTypeDetailsStep'
import ImportFileStep from './steps/ImportFileStep'
import PreviewFileStep from './steps/PreviewFileStep'
import ResultStep from './steps/ResultStep'
import { v4 as uuid } from 'uuid'
import workify from '../../../../utils/workify'
const worker = workify(new Worker(new URL('./file.worker.js', import.meta.url)))

const MAX_IMAGE_UPLOADS_PARALLEL = 5

const Steps = {
  vehicleTypeDetails: 0,
  importFile: 1,
  previewFile: 2,
  result: 3,
}

const Actions = {
  CONFIRM_DETAILS_STEP: 'CONFIRM_DETAILS_STEP',
  CHANGE_STEP: 'CHANGE_STEP',
  IMPORT_FILE_START: 'IMPORT_FILE_START',
  IMPORTED_FILE: 'IMPORTED_FILE',
  ERROR_CREATING_VEHICLE_TYPE: 'ERROR_CREATING_VEHICLE_TYPE',
  ERROR_UPLOADING_FILE: 'ERROR_UPLOADING_FILE',
  ERROR_UPLOADING_IMAGES: 'ERROR_UPLOADING_IMAGES',
  SUCCESSFULLY_UPLOADED_FILE: 'SUCCESSFULLY_UPLOADED_FILE',
  ERROR_IMPORTING_FILE: 'ERROR_IMPORTING_FILE',
  LOADING_FINAL_STEP: 'LOADING_FINAL_STEP',
  UPDATE_FINAL_STEP: 'UPDATE_FINAL_STEP',
}

const INITIAL_STATE = {
  step: Steps.vehicleTypeDetails,
  workbook: {
    data: null,
    images: [],
    fileName: null,
  },
  loading: null,
  details: {},
  importingFile: false,
}

function reducer(state, payload) {
  switch (payload.action) {
    case Actions.CHANGE_STEP:
      return {
        ...state,
        step: payload.data,
      }
    case Actions.CONFIRM_DETAILS_STEP:
      return {
        ...state,
        step: state.step + 1,
        details: payload.data,
      }
    case Actions.IMPORTED_FILE: {
      const isEmpty = workbookIsEmpty(payload.data)
      return {
        ...state,
        step: isEmpty ? state.step : state.step + 1,
        workbook: payload.data,
        importingFile: false,
      }
    }
    case Actions.IMPORT_FILE_START:
      return {
        ...state,
        importingFile: true,
      }
    case Actions.SUCCESSFULLY_UPLOADED_FILE:
      return {
        ...state,
        loadingMessage: null,
        rows: payload.data.rows,
        dStateId: payload.data.dStateId,
        error: payload.data.error,
      }
    case Actions.LOADING_FINAL_STEP:
      return {
        ...state,
        step: state.step + 1,
        loadingMessage: payload.data.loadingMessage,
        error: payload.data.error,
      }
    case Actions.UPDATE_FINAL_STEP:
      return {
        ...state,
        loadingMessage: payload.data.loadingMessage,
        error: payload.data.error,
      }
    case Actions.ERROR_UPLOADING_FILE:
      return {
        ...state,
        loadingMessage: null,
        error: payload.data.error,
      }
    case Actions.ERROR_UPLOADING_IMAGES:
      return {
        ...state,
        loadingMessage: null,
        error: payload.data.error,
      }
    case Actions.ERROR_CREATING_VEHICLE_TYPE:
      return {
        ...state,
        loadingMessage: null,
        error: payload.data.error,
      }
    default:
      return state
  }
}

function workbookIsEmpty(workbook) {
  return workbook.data && workbook.data.length <= 0
}

export default function CreateVehicleTypeBulkDialog({
  open = false,
  onClose = () => {},
  onCreated = () => {},
}) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const [wizardState, setWizardState] = useState(INITIAL_STATE)

  const onCloseDialog = () => {
    setWizardState(INITIAL_STATE)
    onClose()
  }

  const onStepClick = (step) => {
    setWizardState((state) =>
      reducer(state, {
        action: Actions.CHANGE_STEP,
        data: step,
      })
    )
  }

  const onVehicleTypeDetailsStepConfirm = useCallback((details) => {
    setWizardState((state) =>
      reducer(state, {
        action: Actions.CONFIRM_DETAILS_STEP,
        data: details,
      })
    )
  }, [])
  const createVehicleTypeDetailsStep = useCallback(
    () => (
      <VehicleTypeDetailsStep
        data={wizardState.details}
        onConfirm={onVehicleTypeDetailsStepConfirm}
      />
    ),
    [wizardState.details, onVehicleTypeDetailsStepConfirm]
  )

  const onImportFileStepConfirm = useCallback(
    async (didChange, file) => {
      let { workbook } = wizardState
      if (didChange) {
        setWizardState((state) =>
          reducer(state, {
            action: Actions.IMPORT_FILE_START,
          })
        )
        worker.performAction(
          {
            action: 'processImportedFile',
            args: [file],
          },
          (result) => {
            const { sheetData, images, fileName } = result
            const workbook = {
              data: sheetData,
              images: images,
              fileName: fileName,
            }
            setWizardState((state) =>
              reducer(state, {
                action: Actions.IMPORTED_FILE,
                data: workbook,
              })
            )
          }
        )
      } else {
        setWizardState((state) =>
          reducer(state, {
            action: Actions.IMPORTED_FILE,
            data: workbook,
          })
        )
      }
    },
    [wizardState]
  )

  const createImportFileStep = useCallback(() => {
    return (
      <ImportFileStep
        selectedFileName={wizardState.workbook.fileName}
        emptyFile={workbookIsEmpty(wizardState.workbook)}
        onConfirm={onImportFileStepConfirm}
        importingFile={wizardState.importingFile}
      />
    )
  }, [
    wizardState.workbook.fileName,
    wizardState.workbook.data,
    wizardState.importingFile,
    onImportFileStepConfirm,
  ])

  const uploadImages = useCallback(
    async (images) => {
      let output = []

      let progress = 0
      const chunkSize = MAX_IMAGE_UPLOADS_PARALLEL
      for (let index = 0; index < images.length; ) {
        let chunkUploads = []
        let chunkIndex = 0
        for (; chunkIndex < chunkSize; chunkIndex++) {
          const absoluteChunkIndex = chunkIndex + index
          if (absoluteChunkIndex < images.length) {
            let image = images[absoluteChunkIndex]
            const upload = dataProvider
              .upload({ file: image.image })
              .then((result) => {
                const message = t(
                  'create-vehicle-type-dialog.bulk-upload-wizard.results-uploading-images',
                  {
                    imagesCount: images.length,
                    currentImage: ++progress,
                  }
                )
                setWizardState((state) =>
                  reducer(state, {
                    action: Actions.UPDATE_FINAL_STEP,
                    data: {
                      error: null,
                      loadingMessage: message,
                    },
                  })
                )

                return { row: image.row, photoUrl: result.photoUrl }
              })
            chunkUploads.push(upload)
          }
        }
        index += chunkUploads.length
        const results = await Promise.all(chunkUploads)
        output.unshift(...results)
      }
      return output
    },
    [dataProvider, wizardState]
  )

  const onFileImportClick = useCallback(
    async (rows, details) => {
      const { images } = wizardState.workbook
      setWizardState((state) =>
        reducer(state, {
          action: Actions.LOADING_FINAL_STEP,
          data: {
            error: null,
            loadingMessage: t(
              'create-vehicle-type-dialog.bulk-upload-wizard.results-uploading-images',
              { imagesCount: images.length, currentImage: 1 }
            ),
          },
        })
      )

      let urls = []
      try {
        urls = await uploadImages(images)
      } catch (error) {
        setWizardState((state) =>
          reducer(state, {
            action: Actions.ERROR_UPLOADING_IMAGES,
            data: {
              error: Actions.ERROR_UPLOADING_IMAGES,
            },
          })
        )
        return
      }

      const rowImages = {}
      urls.forEach((url) => {
        const images = rowImages[url.row]
        if (images) {
          images.push(url.photoUrl)
          rowImages[url.row] = images
        } else {
          rowImages[url.row] = []
          rowImages[url.row].push(url.photoUrl)
        }
      })
      rows.forEach((row, i) => {
        if (rowImages[i]) {
          row.photoUrl = rowImages[i][0]
          row.photos = rowImages[i]
        } else {
          row.photos = []
        }
      })

      setWizardState((state) =>
        reducer(state, {
          action: Actions.UPDATE_FINAL_STEP,
          data: {
            error: null,
            loadingMessage: t(
              'create-vehicle-type-dialog.bulk-upload-wizard.results-creating-vehicle-type'
            ),
          },
        })
      )
      const data = !details.constructor
        ? {
            type: details.type,
            specificity: details.specificity,
          }
        : {
            type: details.type,
            specificity: details.specificity,
            constructorId: details.constructor.id,
          }
      if (details.member) {
        data.memberId = details.member.id
      }
      if (details.frameworkAgreement) {
        data.frameworkAgreement = details.frameworkAgreement
      }
      if (details.contractNumber) {
        data.contractNumber = details.contractNumber
      }
      if (details.lotNumber) {
        data.lotNumber = details.lotNumber
      }
      if (details.purchaseOrderNumber) {
        data.purchaseOrderNumber = details.purchaseOrderNumber
      }
      try {
        var vehicleType = await dataProvider.create('vehicle-type', { data })
      } catch (error) {
        setWizardState((state) =>
          reducer(state, {
            action: Actions.ERROR_CREATING_VEHICLE_TYPE,
            data: {
              error: Actions.ERROR_CREATING_VEHICLE_TYPE,
            },
          })
        )
        return
      }

      dataProvider.cache.reset()
      dataProvider.member.resetCache()

      setWizardState((state) =>
        reducer(state, {
          action: Actions.UPDATE_FINAL_STEP,
          data: {
            error: null,
            loadingMessage: t(
              'create-vehicle-type-dialog.bulk-upload-wizard.results-uploading-file-data'
            ),
          },
        })
      )
      // Prepare CSV content
      const csvLines = rows.map((row, index) => {
        const vehicleTypeId = vehicleType.data.id
        const nowDate = new Date().toISOString()
        const itemOrder = index + 1
        return [
          vehicleTypeId,
          itemOrder,
          row.nItem,
          escapeCsvField(row.title),
          escapeCsvField(row.description),
          escapeCsvField(row.photoUrl),
          nowDate,
          nowDate,
          escapeCsvField(JSON.stringify(row.photos)),
          uuid(),
        ].join('|')
      })
      const csvHeader =
        'vehicle_type_id,item_order,n_item,title,description,photo_url,createdAt,updatedAt,photos_json,bulk_dedupe_id'
      csvLines.unshift(csvHeader)
      const csvData = csvLines.join('\n')

      // Upload file
      try {
        const { data: uploadDetails } =
          await dataProvider.vehicleType.startItemsBulkUpload()
        await dataProvider.vehicleType.performBulkUpload(uploadDetails, csvData)
        setWizardState((state) =>
          reducer(state, {
            action: Actions.SUCCESSFULLY_UPLOADED_FILE,
            data: {
              rows,
              dStateId: uploadDetails.dStateId,
              error: null,
            },
          })
        )
      } catch (error) {
        setWizardState((state) =>
          reducer(state, {
            action: Actions.ERROR_UPLOADING_FILE,
            data: {
              error: Actions.ERROR_UPLOADING_FILE,
            },
          })
        )
      }
    },
    [dataProvider, wizardState]
  )

  // Function to escape and quote a CSV field if necessary
  function escapeCsvField(field) {
    if (field == null || field == undefined) return '""' // Handle null or undefined
    const strField = String(field) // Ensure the field is a string
    if (
      strField.includes('"') ||
      strField.includes('\n') ||
      strField.includes('|')
    ) {
      // Escape double quotes by doubling them
      const escaped = strField.replace(/"/g, '""')
      return `"${escaped}"` // Wrap the escaped content in double quotes
    }
    return strField // Return the field as is if no special handling needed
  }

  const createPreviewFileStep = useCallback(
    () => (
      <PreviewFileStep
        workbookData={wizardState.workbook.data}
        details={wizardState.details}
        onConfirm={onFileImportClick}
      />
    ),
    [wizardState.workbook.data, wizardState.details, onFileImportClick]
  )

  const onResultConfirm = useCallback(() => {
    setWizardState(INITIAL_STATE)
    onCreated()
  }, [onCreated])
  const createResultStep = useCallback(() => {
    return (
      <ResultStep
        loadingMessage={wizardState.loadingMessage}
        dStateId={wizardState.dStateId}
        error={wizardState.error}
        onConfirm={onResultConfirm}
      />
    )
  }, [onResultConfirm, wizardState])

  const stepComponent = useMemo(() => {
    if (wizardState.step === Steps.vehicleTypeDetails) {
      return createVehicleTypeDetailsStep()
    }
    if (wizardState.step === Steps.importFile) {
      return createImportFileStep()
    }
    if (wizardState.step === Steps.previewFile) {
      return createPreviewFileStep()
    }
    if (wizardState.step === Steps.result) {
      return createResultStep()
    }
    throw new Error(`Illegal step: ${wizardState.step}`)
  }, [
    createImportFileStep,
    createPreviewFileStep,
    createVehicleTypeDetailsStep,
    createResultStep,
    wizardState,
  ])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      title={t('create-vehicle-type-dialog.title')}
      width="md"
      hideActions
      classes={{ root: classes.noPadding }}
      disableEnforceFocus
    >
      <Stack direction="column" spacing={4} style={{ marginBottom: '1px' }}>
        <div className={classes.wizardHeader}>
          <WizardHeader
            numberOfSteps={4}
            selectedStep={wizardState.step}
            onStepClick={onStepClick}
          />
        </div>
        <div className={classes.stepPadding}>{stepComponent}</div>
      </Stack>
    </BaseDialog>
  )
}
