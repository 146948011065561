import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography, TextField } from '@mui/material'
import AutoCompleteInput from '../../../../../AutoCompleteInput'

export default function VehicleTypeDetailsStep({ data, onConfirm }) {
  const { t } = useTranslation()

  const [error, setError] = useState('')
  const [type, setType] = useState((data && data.type) || '')
  const [specificity, setSpecificity] = useState(
    (data && data.specificity) || ''
  )
  const [constructeur, setConstructor] = useState(
    (data && data.constructeur) || null
  )
  const [member, setMember] = useState((data && data.member) || null)
  const [frameworkAgreement, setFrameworkAgreement] = useState(
    (data && data.frameworkAgreement) || null
  )
  const [contractNumber, setContractNumber] = useState(
    (data && data.contractNumber) || null
  )
  const [lotNumber, setLotNumber] = useState((data && data.lotNumber) || null)
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(
    (data && data.purchaseOrderNumber) || null
  )

  const onConfirmData = () => {
    if (type.length === 0) {
      setError('type_required')
      return
    }

    if (specificity.length === 0) {
      setError('specificity_required')
      return
    }

    const newData = !constructeur
      ? {
          type,
          specificity,
        }
      : {
          type,
          specificity,
          constructeur,
        }
    if (member) {
      newData.member = member
    }
    if (frameworkAgreement) {
      newData.frameworkAgreement = frameworkAgreement
    }
    if (contractNumber) {
      newData.contractNumber = contractNumber
    }
    if (lotNumber) {
      newData.lotNumber = lotNumber
    }
    if (purchaseOrderNumber) {
      newData.purchaseOrderNumber = purchaseOrderNumber
    }

    onConfirm(newData) // Pass data to parent
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">Informations</Typography>
      </Grid>
      <Grid item style={{ display: 'flex' }} xs={6}>
        <TextField
          variant="standard"
          required
          error={error === 'type_required'}
          helperText={t('common.required')}
          id="type"
          label={t('create-vehicle-type-dialog.type')}
          fullWidth
          value={type}
          onChange={(event) => setType(event.target.value)}
        />
      </Grid>
      <Grid item style={{ display: 'flex' }} xs={6}>
        <TextField
          variant="standard"
          required
          error={error === 'specificity_required'}
          helperText={t('common.required')}
          id="specificity"
          label={t('create-vehicle-type-dialog.specificity')}
          fullWidth
          value={specificity}
          onChange={(event) => setSpecificity(event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AutoCompleteInput
          onChange={setConstructor}
          label={t('create-vehicle-type-dialog.constructor-label')}
          resource="/constructors/autocomplete"
          value={constructeur}
          canDelete
        />
      </Grid>
      <Grid item xs={6}>
        <AutoCompleteInput
          onChange={setMember}
          label={t('create-vehicle-type-dialog.member')}
          resource="vehicle-type/autocomplete-members"
          value={member}
          canDelete
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          id="frameworkAgreement"
          label={t('create-vehicle-type-dialog.framework-agreement')}
          fullWidth
          value={data.frameworkAgreement}
          onChange={(event) => setFrameworkAgreement(event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          id="contractNumber"
          label={t('create-vehicle-type-dialog.contract-number')}
          fullWidth
          value={data.contractNumber}
          onChange={(event) => setContractNumber(event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          id="lotNumber"
          label={t('create-vehicle-type-dialog.lot-number')}
          fullWidth
          value={data.lotNumber}
          onChange={(event) => setLotNumber(event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          id="purchaseOrderNumber"
          label={t('create-vehicle-type-dialog.purchase-order-number')}
          fullWidth
          value={data.purchaseOrderNumber}
          onChange={(event) => setPurchaseOrderNumber(event.target.value)}
        />
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={onConfirmData}>
          Continuer
        </Button>
      </Grid>
    </Grid>
  )
}
