import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import BaseDialog from '../BaseDialog'
import useStyles from './styles'
import { useDataApi } from '../../context/DataApiContext'

import WizardHeader from '../WizardHeader'
import ImportPVAdmissionFileStep from './steps/ImportFileStep'
import PreviewPVAdmissionFileStep from './steps/PreviewFileStep'
import ResultPVAdmissionStep from './steps/ResultStep'

const Steps = {
  importFile: 0,
  previewFile: 1,
  result: 2,
}

const Actions = {
  CHANGE_STEP: 'CHANGE_STEP',
  IMPORTED_FILE: 'IMPORTED_FILE',
  IMPORT_FILE_START: 'IMPORT_FILE_START',
  ERROR_UPLOADING_FILE: 'ERROR_UPLOADING_FILE',
  SUCCESSFULLY_UPLOADED_FILE: 'SUCCESSFULLY_UPLOADED_FILE',
  ERROR_IMPORTING_FILE: 'ERROR_IMPORTING_FILE',
  LOADING_FINAL_STEP: 'LOADING_FINAL_STEP',
}

const INITIAL_STATE = {
  step: Steps.importFile,
  file: null,
  fileUrl: null,
  fileName: null,
  loading: null,
  importingFile: false,
}

function reducer(state, payload) {
  switch (payload.action) {
    case Actions.CHANGE_STEP:
      return {
        ...state,
        step: payload.data,
      }
    case Actions.IMPORTED_FILE: {
      const exists = fileExists(payload.data.fileUrl)
      return {
        ...state,
        step: exists ? state.step + 1 : state.step,
        file: payload.data.file,
        fileUrl: payload.data.fileUrl,
        fileName: payload.data.fileName,
        importingFile: false,
      }
    }
    case Actions.IMPORT_FILE_START:
      return {
        ...state,
        importingFile: true,
      }
    case Actions.SUCCESSFULLY_UPLOADED_FILE:
      return {
        ...state,
        loadingMessage: null,
        uploadSuccess: true,
        error: payload.data.error,
      }
    case Actions.LOADING_FINAL_STEP:
      return {
        ...state,
        step: state.step + 1,
        loadingMessage: payload.data.loadingMessage,
        error: payload.data.error,
      }
    case Actions.ERROR_UPLOADING_FILE:
      return {
        ...state,
        loadingMessage: null,
        error: payload.data.error,
      }
    default:
      return state
  }
}

function fileExists(file) {
  return file !== null && file !== undefined
}

export default function ManagePVAdmissionDialog({
  open = false,
  onClose = () => {},
  onCreated = () => {},
}) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const [wizardState, setWizardState] = useState(INITIAL_STATE)

  const onCloseDialog = () => {
    setWizardState(INITIAL_STATE)
    onClose()
  }

  const onStepClick = (step) => {
    setWizardState((state) =>
      reducer(state, {
        action: Actions.CHANGE_STEP,
        data: step,
      })
    )
  }

  const onImportFileStepConfirm = useCallback(
    async (didChange, file) => {
      let data = wizardState
      if (didChange) {
        setWizardState((state) =>
          reducer(state, {
            action: Actions.IMPORT_FILE_START,
          })
        )
        dataProvider.chassis.uploadPVAdmissionPreview(file).then((response) => {
          setWizardState((state) =>
            reducer(state, {
              action: Actions.IMPORTED_FILE,
              data: {
                fileUrl: response.fileUrl,
                fileName: file.name,
                file: file,
              },
            })
          )
        })
      } else {
        setWizardState((state) =>
          reducer(state, {
            action: Actions.IMPORTED_FILE,
            data: data,
          })
        )
      }
    },
    [wizardState]
  )

  const createImportFileStep = useCallback(() => {
    return (
      <ImportPVAdmissionFileStep
        selectedFileName={wizardState.fileName}
        emptyFile={!fileExists(wizardState.fileUrl)}
        onConfirm={onImportFileStepConfirm}
        importingFile={wizardState.importingFile}
      />
    )
  }, [
    wizardState.fileName,
    wizardState.fileUrl,
    wizardState.importingFile,
    onImportFileStepConfirm,
  ])

  const onFileSubmit = useCallback(async () => {
    setWizardState((state) =>
      reducer(state, {
        action: Actions.LOADING_FINAL_STEP,
        data: {
          error: null,
          loadingMessage: t(
            'create-vehicle-type-dialog.bulk-upload-wizard.results-uploading-file-data'
          ),
        },
      })
    )

    // Upload file
    try {
      const { file, fileUrl } = wizardState

      // Upload new file to S3
      // Get doc or docx document type from file
      const documentFormat = file.name.split('.').pop()
      dataProvider.chassis
        .uploadPVAdmissionManagement(file, documentFormat)
        .then(() => {
          // Delete preview file from S3
          dataProvider.chassis
            .deletePVAdmissionPreview(fileUrl)
            .then(() => {
              setWizardState((state) =>
                reducer(state, {
                  action: Actions.SUCCESSFULLY_UPLOADED_FILE,
                  data: {
                    error: null,
                  },
                })
              )
            })
            .catch(() => {
              setWizardState((state) =>
                reducer(state, {
                  action: Actions.ERROR_UPLOADING_FILE,
                  data: {
                    error: Actions.ERROR_UPLOADING_FILE,
                  },
                })
              )
            })
        })
        .catch(() => {
          setWizardState((state) =>
            reducer(state, {
              action: Actions.ERROR_UPLOADING_FILE,
              data: {
                error: Actions.ERROR_UPLOADING_FILE,
              },
            })
          )
        })
    } catch (error) {
      setWizardState((state) =>
        reducer(state, {
          action: Actions.ERROR_UPLOADING_FILE,
          data: {
            error: Actions.ERROR_UPLOADING_FILE,
          },
        })
      )
    }
  }, [dataProvider, wizardState])

  const createPreviewFileStep = useCallback(
    () => (
      <PreviewPVAdmissionFileStep
        file={wizardState.fileUrl}
        onConfirm={onFileSubmit}
      />
    ),
    [wizardState.file, onFileSubmit]
  )

  const onResultConfirm = useCallback(() => {
    setWizardState(INITIAL_STATE)
    onCreated()
  }, [onCreated])
  const createResultStep = useCallback(() => {
    return (
      <ResultPVAdmissionStep
        loadingMessage={wizardState.loadingMessage}
        error={wizardState.error}
        isDone={wizardState.uploadSuccess}
        onConfirm={onResultConfirm}
      />
    )
  }, [onResultConfirm, wizardState])

  const stepComponent = useMemo(() => {
    if (wizardState.step === Steps.importFile) {
      console.log('Creating import file step')
      return createImportFileStep()
    }
    if (wizardState.step === Steps.previewFile) {
      return createPreviewFileStep()
    }
    if (wizardState.step === Steps.result) {
      return createResultStep()
    }
    throw new Error(`Illegal step: ${wizardState.step}`)
  }, [
    createImportFileStep,
    createPreviewFileStep,
    createResultStep,
    wizardState,
  ])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      title={t('pv-admission-management.manage-pv-admission-wizard.title')}
      width="md"
      hideActions
      classes={{ root: classes.noPadding }}
      disableEnforceFocus
    >
      <Stack direction="column" spacing={4} style={{ marginBottom: '1px' }}>
        <div className={classes.wizardHeader}>
          <WizardHeader
            numberOfSteps={3}
            selectedStep={wizardState.step}
            onStepClick={onStepClick}
          />
        </div>
        <div className={classes.stepPadding}>{stepComponent}</div>
      </Stack>
    </BaseDialog>
  )
}
