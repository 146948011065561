import React, { useState, useEffect } from 'react'
import { Grid, Button } from '@mui/material'

// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

// components
import PageTitle from '../../components/PageTitle'
import { useDataApi } from '../../context/DataApiContext'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import Loading from '../../components/Loading'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import ManagePVAdmissionDialog from '../../components/PVAdmissionManagement'

export default function PVAdmissionManagement() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [failedLoad, setFailedLoad] = useState(false)
  const [document, setDocument] = useState(null)
  const [openManagePVAdmission, setOpenManagePVAdmission] = useState(false)

  const onManageDocument = () => {
    setOpenManagePVAdmission(true)
  }

  const fetchDocument = () => {
    dataProvider.chassis
      .getPVAdmissionManagement()
      .then((response) => setDocument(response.data))
      .catch(() => setFailedLoad(true))
  }

  useEffect(() => {
    fetchDocument()
  }, [dataProvider])

  useEffect(() => {
    if (!failedLoad) {
      return
    }

    showNotification('error', t('errors.api-error'))
    setFailedLoad(false)
  }, [showNotification, failedLoad, t])

  // still loading
  if (!document) {
    return <Loading />
  }
  // local
  return (
    <>
      <ManagePVAdmissionDialog
        open={openManagePVAdmission}
        onClose={() => {
          setOpenManagePVAdmission(false)
        }}
        onCreated={() => {
          fetchDocument()
          setOpenManagePVAdmission(false)
        }}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('pv-admission-management.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onManageDocument}
          >
            {t('pv-admission-management.manage-pv-admission')}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.previewGridContainer}>
        <Grid item className={classes.previewGridItem}>
          <DocViewer
            style={{ height: 550 }}
            config={{
              header: {
                disableHeader: false,
                disableFileName: false,
                retainURLParams: false,
              },
            }}
            pluginRenderers={DocViewerRenderers}
            documents={[document]}
          />
        </Grid>
      </Grid>
    </>
  )
}
