import React from 'react'

// components
import { Button } from '@mui/material'
import enums from '../../utils/enums'

export default function generate({ t, classes, onShowPhotos }) {
  return [
    {
      name: 'control.chassis.nChassis',
      label: t('common.chassis'),
      options: {
        sort: false,
      },
    },
    {
      name: 'controlItem.nItem',
      label: t('common.nItem'),
      options: {
        sort: false,
      },
    },
    {
      name: 'controlItem.title',
      label: t('controls-detail-list.item-title'),
      options: {
        sort: false,
      },
    },
    {
      name: 'description',
      label: 'Non-conformité',
      options: {
        sort: false,
      },
    },
    {
      name: 'conformity',
      label: t('common.conformity'),
      options: {
        sort: false,
        customBodyRender: (val) => {
          switch (val) {
            case enums.CONFORMITY.INVALID:
              return t('conformity.invalid')
            case enums.CONFORMITY.VALID:
              return t('conformity.valid')
            case enums.CONFORMITY.AWAITING_CONTROL:
              return t('conformity.waiting-result')
            case enums.CONFORMITY.DOES_NOT_APPLY:
              return t('conformity.does-not-apply')
            default:
              return t('errors.not-valid')
          }
        },
      },
    },
    {
      name: 'control.chassis.nParc',
      label: t('common.nParc'),
    },
    {
      name: 'control.chassis.location',
      label: t('common.location'),
    },
    {
      name: 'photos',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val) => {
          if (val.length > 0) {
            return (
              <Button
                className={classes.linkButton}
                variant="text"
                onClick={() => onShowPhotos(val)}
                color="primary"
              >
                {`${val.length} ${t('common.photo')}`}
              </Button>
            )
          }
          return t('common.noPhoto')
        },
      },
    },
  ]
}
