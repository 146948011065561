import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  statisticsFiltersContainer: {
    marginTop: theme.spacing(4),
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  statisticsContainer: {
    marginTop: theme.spacing(2),
  },
  statisticsActionsContainer: {
    marginTop: '10px',
  },
}))
