import React from "react";
import PropTypes from 'prop-types';
import { Box, Chip } from "@mui/material";

ChartFilterBadges.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  style: PropTypes.object,
};

export default function ChartFilterBadges({ filters, style }) {
  return (
    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", marginTop: 2, marginLeft: 1 }}>
      {filters.length > 0 && (
        filters.map((filter, index) => (
          <Chip
            key={index}
            label={`${filter.name}: ${filter.value}`}
            style={style}
            variant="outlined"
            size="small"
          />
        ))
      )}
    </Box>
  );
}