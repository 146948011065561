import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  previewGridContainer: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    overflow: 'hidden',
    height: '100%',
  },
  previewGridItem: {
    width: '100%',
    overflow: 'hidden',
    height: '100%',
    '& .hysiap': {
      overflowY: 'hidden !important',
      height: '100% !important',
    },
    '& .hcThrB': {
      overflowY: 'hidden !important',
      height: '100% !important',
    },
  },
}))
