// Date formatters
function toLocalISOString(date) {
  if (!date) return ''

  const localOffsetDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  )
  return localOffsetDate.toISOString().slice(0, 19) // Keep up to seconds
}

function toLocalDateString(date) {
  if (!date) return ''

  // Format date to dd-MM-yyyy
  const localOffsetDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  )
  return localOffsetDate
    .toISOString()
    .slice(0, 10)
    .split('-')
    .reverse()
    .join('/')
}

export { toLocalISOString, toLocalDateString }
