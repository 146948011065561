import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Skeleton } from '@mui/material';
import { VictoryPie, VictoryTheme, VictoryTooltip, VictoryChart, VictoryLabel } from 'victory';
import ChartFilterBadges from '../chart-filter-badges';

PieChart.propTypes = {
  title: PropTypes.string,
  noDataMessage: PropTypes.string,
  colorScale: PropTypes.array,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      y: PropTypes.number,
    })
  ),
  style: PropTypes.shape({
    pie: PropTypes.object,
    tooltip: PropTypes.object,
    title: PropTypes.object,
    activeFilters: PropTypes.object,
  }),
  tooltipLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  enableTooltip: PropTypes.bool,
  activeFilters: PropTypes.array,
};

export default function PieChart({ title, noDataMessage, colorScale, data, style, tooltipLabel, isLoading, enableTooltip, activeFilters }) {
  const theme = useTheme();
  const dataLength = data?.length ?? 0;

  // Function to format the tooltip label
  const formatTooltip = (x, y) => {
    const total = data.reduce((sum, item) => sum + item.y, 0);
    const percentage = ((y / total) * 100).toFixed(2);
    const roundedY = Math.round(y * 100) / 100; // Round to 2 decimal places
    return tooltipLabel.replace('{x}', x).replace('{y}', roundedY).replace('{percentage}', percentage);
  };

  if (isLoading) {
    return (
      <Box>
        <Typography style={style?.title} marginLeft={1} marginBottom={1}>
          {title}
        </Typography>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={350}
          animation="pulse"
          style={{ borderRadius: 8, backgroundColor: theme.palette.grey[200] }}
        />
      </Box>
    );
  }

  if (dataLength === 0) {
    return (
      <Box height={350}>
        <Typography style={style?.title} marginLeft={1} marginBottom={4}>
          {title}
        </Typography>
        <ChartFilterBadges filters={activeFilters} style={style?.activeFilters} />
        <VictoryChart
          theme={{
            axis: {
              style: {
                axis: { stroke: "none" },
                grid: { stroke: "none" },
                ticks: { stroke: "none" },
                tickLabels: { fill: "none" },
              },
            },
          }}
        >
          <VictoryLabel
            text={noDataMessage}
            x={225}
            y={150}
            textAnchor="middle"
            style={{ fontSize: 18, fill: theme.palette.text.disabled }}
          />
        </VictoryChart>
      </Box>
    );
  }

  return (
    <Box>
      <Typography style={style?.title} marginLeft={1} marginBottom={4}>
        {title}
      </Typography>
      <ChartFilterBadges filters={activeFilters} style={style?.activeFilters} />
      <VictoryPie
        theme={VictoryTheme.clean}
        data={data}
        colorScale={colorScale}
        labelIndicator
        labelIndicatorInnerOffset={10}
        style={style?.pie}
        labels={({ datum }) => formatTooltip(datum.x, datum.y)}
        labelComponent={enableTooltip && <VictoryTooltip constrainToVisibleArea sx={{ position: 'relative', zIndex: 9999 }} style={style?.tooltip} />}
        animate={{ duration: 1000, easing: 'linear', onLoad: { duration: 1500 } }}
        innerRadius={50} // Optional: Creates a donut chart effect
        padding={{ top: 50, bottom: 50, left: 50, right: 50 }}
      />
    </Box>
  );
}