/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography, CircularProgress } from '@mui/material'
import useStyles from './styles'
import { ReactComponent as SuccessIcon } from '../../../../assets/Icons_Success.svg'
import { ReactComponent as ErrorIcon } from '../../../../assets/Icons_Error.svg'

export default function ResultPVAdmissionStep({
  loadingMessage,
  error,
  isDone,
  onConfirm = () => {},
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const Errors = {
    ERROR_UPLOADING_FILE: 'ERROR_UPLOADING_FILE',
  }

  const isError = error === Errors.ERROR_UPLOADING_FILE
  const message = loadingMessage
    ? loadingMessage
    : isError
    ? t('create-vehicle-type-dialog.bulk-upload-wizard.results-dstate-error')
    : isDone
    ? t('create-vehicle-type-dialog.bulk-upload-wizard.results-success')
    : t('create-vehicle-type-dialog.bulk-upload-wizard.results-loading')

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
    >
      {!isError && !isDone && !error && (
        <Grid item>
          <CircularProgress
            size="6rem"
            visibility={!isError && !isDone ? 'visible' : 'hidden'}
          />
        </Grid>
      )}
      {(!error && (
        <>
          {isDone && (
            <Grid item>
              <SuccessIcon visibility={isDone ? 'visible' : 'hidden'} />
            </Grid>
          )}
          {isError && (
            <Grid item>
              <ErrorIcon visibility={isError ? 'visible' : 'hidden'} />
            </Grid>
          )}
          <Grid item>
            <Typography className={classes.message}>{message}</Typography>
          </Grid>
        </>
      )) ||
        (error && (
          <>
            <Grid item>
              <ErrorIcon visibility={error ? 'visible' : 'hidden'} />
            </Grid>
            <Grid item>
              <Typography className={classes.message}>
                {error === Errors.ERROR_UPLOADING_FILE &&
                  t(
                    'create-vehicle-type-dialog.bulk-upload-wizard.results-dstate-error'
                  )}
              </Typography>
            </Grid>
          </>
        ))}
      <Grid item container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          disabled={!isDone}
          onClick={onConfirm}
        >
          Terminer
        </Button>
      </Grid>
    </Grid>
  )
}
