import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import useStyles from './styles'
// import { useTranslation } from 'react-i18next'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'

export default function PreviewPVAdmissionFileStep({
  file,
  onConfirm = () => {},
}) {
  const classes = useStyles()
  // const { t } = useTranslation()

  const prepareAndSubmit = () => {
    const mappedData = {
      url: file,
    }
    onConfirm(mappedData, file) // Images will probably be in the state of the parent
  }

  const document = {
    uri: file,
    fileType: 'doc',
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">Prévisualisation</Typography>
      </Grid>
      <Grid item className={classes.previewGridContainer}>
        {file && (
          <Grid item className={classes.previewGridItem}>
            <DocViewer
              style={{ height: 500 }}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false,
                },
              }}
              pluginRenderers={DocViewerRenderers}
              documents={[document]}
            />
          </Grid>
        )}
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={prepareAndSubmit}>
          Soumettre
        </Button>
      </Grid>
    </Grid>
  )
}
